import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LayoutC from '../containers/Layout';
/**
 * Pages
 */
const Home = React.lazy(() => import('../pages/Home'));
const Login = React.lazy(() => import('../pages/login'));
const Test2 = React.lazy(() => import('../pages/test/pdf2'));
const Test = React.lazy(() => import('../pages/test/pdfs'));
// const Jspdf = React.lazy(() => import('../pages/test/jsPdf'));
const Customization = React.lazy(() => import('../pages/customization'));
const PayrollCompanies = React.lazy(() => import('../pages/payroll/payrollCompanies'));
const PayrollCompaniesList = React.lazy(() => import('../pages/payroll/payrollCompaniesList'));
const PayrollCompaniesDetail = React.lazy(() => import('../pages/payroll/payrollCompaniesDetail'));
const PayrollUpdate = React.lazy(() => import('../pages/payroll/payrollUpdate'));
const PayrollRegister = React.lazy(() => import('../pages/payroll/payrollRegister'));
const EmployeeRequestList = React.lazy(() => import('../pages/payroll/employeeRequestList'));
const EmployeeRequestDetail = React.lazy(() => import('../pages/payroll/employeeRequestDetail'));
const EmployeeRegister = React.lazy(() => import('../pages/payroll/employeeRegister'));
const InvestorRequestList = React.lazy(() => import('../pages/investor/investorRequestList'));
const InvestorDetail = React.lazy(() => import('../pages/investor/investorDetail'));
const InvestorList = React.lazy(() => import('../pages/investor/investorList'));
const Utilities = React.lazy(() => import('../pages/utilities'));
const Characteristics = React.lazy(() => import('../pages/characteristics'));
const SellerListP = React.lazy(() => import('../pages/sellers/sellersList'));
const SellersDetailPage = React.lazy(() => import('../pages/sellers/sellersDetail'));
const BeneficiariesListP = React.lazy(() => import('../pages/beneficiaries/beneficiariesList'));
const AttributesDetailP = React.lazy(() => import('../pages/credits/attributesDetail/index'));
const CreateBeneficiaryP = React.lazy(() => import('../pages/beneficiaries/createBeneficiary'));
const BeneficiariesDetailP = React.lazy(
  () => import('../pages/beneficiaries/beneficiariesDetail/index')
);

const MoneyInFavorList = React.lazy(() => import('../pages/treasury/moneyInFavorList'));
const MoneyInFavorDetailP = React.lazy(() => import('../pages/treasury/moneyInFavorDetail'));
const PaymentReports = React.lazy(() => import('../pages/treasury/paymentReports'));
const RejectedCreditsList = React.lazy(() => import('../pages/credits/rejectedCreditsList'));
// TREASURY
const Expense = React.lazy(() => import('../pages/treasury/expenses'));
const ExpensesListP = React.lazy(() => import('../pages/treasury/egressList/index'));
const NewIncome = React.lazy(() => import('../pages/treasury/newIncome'));
const EntryListP = React.lazy(() => import('../pages/treasury/entryList/index'));
const EntryDetailP = React.lazy(() => import('../pages/treasury/entryDetail/index'));
const IncomeCreated = React.lazy(() => import('../pages/treasury/income/index'));
const TreasuryListCredits = React.lazy(() => import('../pages/treasury/listCredits/index'));
const AnnulmentsRegister = React.lazy(() => import('../pages/treasury/annulments/index'));
const CanceledListP = React.lazy(() => import('../pages/treasury/canceledList'));
const CanceledListPage = React.lazy(() => import('../pages/treasury/canceledListDetail'));
const EgressListDetail = React.lazy(() => import('../pages/treasury/egressListDetail'));
const MoneyInFavor = React.lazy(() => import('../pages/treasury/moneyInFavor'));
const AccountingMovement = React.lazy(() => import('../pages/treasury/ReportMoconta'));
const insuranceReports = React.lazy(() => import('../pages/treasury/insuranceReports'));

const CreditReports = React.lazy(() => import('../pages/treasury/creditReports'));
const QuotaReports = React.lazy(() => import('../pages/treasury/quotaReports/index'));
const CreditsList = React.lazy(() => import('../pages/credits/creditsList'));
const CreditsList360 = React.lazy(() => import('../pages/credits/creditsList360'));
const CreditMigration = React.lazy(() => import('../pages/credits/creditMigration'));
const CreditGroupList = React.lazy(() => import('../pages/credits/creditGroupList'));
// const CreditDetail = React.lazy(() => import('../pages/credits/creditDetail'));
const CreateAttributeGroupP = React.lazy(() => import('../pages/credits/createAttributeGroup'));
const ReliquidacionP = React.lazy(() => import('../pages/credits/reliquidacion'));
const SimulatorP = React.lazy(() => import('../pages/credits/simulator'));
const AttributesList = React.lazy(() => import('../pages/credits/attributesList'));
const ElectronicPaymentsP = React.lazy(() => import('../pages/electronicPayments/payments'));
const ElectronicPaymentDetails = React.lazy(
  () => import('../pages/electronicPayments/paymentDetails')
);
const GroupAttributeP = React.lazy(() => import('../pages/credits/groupAttribute'));
const CreditTypeListP = React.lazy(() => import('../pages/credits/creditTypeList'));
const CreditTypeDetailP = React.lazy(() => import('../pages/credits/creditTypeDetail'));
const ClosingMonthlyP = React.lazy(() => import('../pages/wallet/ClosingMonthly/index'));
const PortfolioByAge = React.lazy(() => import('../pages/wallet/PortfolioByAge/index'));
const AccountStatus = React.lazy(() => import('../pages/wallet/AccountStatus'));
const AccountStatementDetailPages = React.lazy(
  () => import('../pages/wallet/accountStatementDetail')
);
const PageCustomer = React.lazy(() => import('../pages/user/customerList'));
const PageUser = React.lazy(() => import('../pages/user/userList'));
const UserAdministratorsP = React.lazy(() => import('../pages/user/userDetail'));
const GroupAttributeDetail = React.lazy(() => import('../pages/credits/groupAttributeDetail'));
// const CreditRequest = React.lazy(() => import('../pages/phases/creditRequest'));
const Cirenio = React.lazy(() => import('../pages/test/cirenio'));
const CreditTypeCreateP = React.lazy(() => import('../pages/credits/creditTypeCreate'));
const InvestorRegisterPage = React.lazy(() => import('../pages/investor/investorRegister'));
const UserRegisterP = React.lazy(() => import('../pages/user/userRegister'));
const AttributeTypeRegisterPage = React.lazy(
  () => import('../pages/credits/attributeTypeRegister')
);
const SellersRegisterPage = React.lazy(() => import('../pages/sellers/sellersRegister'));
const ParametersAccountP = React.lazy(() => import('../pages/parameters/parametersAccount'));
const ParametersGeneralP = React.lazy(() => import('../pages/parameters/parametersGeneral'));
const ParametersEmailP = React.lazy(() => import('../pages/parameters/parametersEmail'));

const CreatedGroupLostP = React.lazy(() => import('../pages/lost/CreatedGroupLost'));
const LostListP = React.lazy(() => import('../pages/lost/listLost/index'));
const CreateBatchP = React.lazy(() => import('../pages/lost/createBatchGroups'));

const HandleDocsNogue = React.lazy(() => import('../pages/test/handleDocs'));
const CreditFormOpen = React.lazy(() => import('../pages/credits/creditFormOpen'));
const FinishCreditFormOpen = React.lazy(() => import('../pages/credits/finishOpenCredit'));
const OpenForm = React.lazy(() => import('../pages/credits/OpenForm'));
const AdministrationP = React.lazy(() => import('../pages/administration'));
const TransactionDetailP = React.lazy(
  () => import('../pages/electronicPayments/transactionDetail')
);
const RetrievePaymentResponse = React.lazy(
  () => import('../pages/electronicPayments/transactionDetail/responsePayment')
);
const IntegrationResponseAdo = React.lazy(() => import('../pages/integrationResponseAdo'));
/** Phases Rework */
const PhaseCards = React.lazy(() => import('../pages/phases/phaseCard'));
const PhaseListCredits = React.lazy(() => import('../pages/phases/phaseListCredits'));
const Request = React.lazy(() => import('../pages/phases/request'));
/** Phases Rework END */
const ActiveAccountPayroll = React.lazy(() => import('../pages/payroll/activeAccontPayroll'));
const SetPasswordP = React.lazy(() => import('../pages/setPassword'));
const RecoverPasswordP = React.lazy(() => import('../pages/recoverPass'));
const EditCreditP = React.lazy(() => import('../pages/phases/editCredit'));

const UserVerification = React.lazy(() => import('../pages/user/userVerification'));
const AccountingReports = React.lazy(() => import('../pages/reports/index'));
const GroupCreditLinePage = React.lazy(() => import('../pages/credits/groupCreditLine/index'));
const CreateGroupLineP = React.lazy(() => import('../pages/credits/createGropuCreditLine'));
const GroupCreditLineDetailP = React.lazy(() => import('../pages/groupCreditLineDetail'));
const thirdsReport = React.lazy(() => import('../pages/thirds'));

interface ProtectedRouteProps {
  page: React.ComponentType<any>;
  isLogged: boolean;
  path: string;
}

const ProtectedRoute = ({ isLogged, page: Page, path }: ProtectedRouteProps) => {
  if (!isLogged) {
    return <Login />;
  }

  return (
    <LayoutC path={path}>
      <Page />
    </LayoutC>
  );
};

const AllRoutes = ({ isLogged }: { isLogged: boolean }) => {
  return (
    <Routes>
      <Route index element={<ProtectedRoute path="/" page={Home} isLogged={isLogged} />} />
      <Route
        path="/personalizarColores"
        element={
          <ProtectedRoute path="/personalizarColores" page={Customization} isLogged={isLogged} />
        }
      />
      <Route
        path="/cartera/estado-cuenta"
        element={
          <ProtectedRoute path="/cartera/estado-cuenta" page={AccountStatus} isLogged={isLogged} />
        }
      />
      <Route
        path="/cartera/estado-cuenta-detalle/:pcId"
        element={
          <ProtectedRoute
            path="/cartera/estado-cuenta"
            page={AccountStatementDetailPages}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/vendedores"
        element={<ProtectedRoute path="/vendedores" page={SellerListP} isLogged={isLogged} />}
      />
      <Route
        path="/vendedores/detalle/:sId"
        element={<ProtectedRoute path="/vendedores" page={SellersDetailPage} isLogged={isLogged} />}
      />
      <Route
        path="/beneficiarios"
        element={
          <ProtectedRoute path="/beneficiarios" page={BeneficiariesListP} isLogged={isLogged} />
        }
      />
      <Route
        path="/beneficiarios/registro"
        element={
          <ProtectedRoute
            path="/beneficiarios/registro"
            page={CreateBeneficiaryP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/beneficiarios/registro/:bId"
        element={
          <ProtectedRoute
            path="/beneficiarios/registro"
            page={CreateBeneficiaryP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/usuarios/clientes"
        element={
          <ProtectedRoute path="/usuarios/clientes" page={PageCustomer} isLogged={isLogged} />
        }
      />
      <Route
        path="/usuarios/"
        element={<ProtectedRoute path="/usuarios/" page={PageUser} isLogged={isLogged} />}
      />
      <Route
        path="/usuarios/detalle/:uId"
        element={<ProtectedRoute path="/usuarios" page={UserAdministratorsP} isLogged={isLogged} />}
      />
      <Route
        path="/usuarios/registro"
        element={
          <ProtectedRoute path="/usuarios/registro" page={UserRegisterP} isLogged={isLogged} />
        }
      />
      <Route
        path="/usuarios/registro/:uId"
        element={
          <ProtectedRoute path="/usuarios/registro" page={UserRegisterP} isLogged={isLogged} />
        }
      />
      <Route
        path="/estado-cuenta"
        element={<ProtectedRoute path="/estado-cuenta" page={AccountStatus} isLogged={isLogged} />}
      />
      <Route
        path="/empresas/"
        element={
          <ProtectedRoute path="/empresas/" page={PayrollCompaniesList} isLogged={isLogged} />
        }
      />
      <Route
        path="/empresas/registro/"
        element={
          <ProtectedRoute path="/empresas/registro" page={PayrollCompanies} isLogged={isLogged} />
        }
      />
      <Route
        path="/empresas/editar/:cId"
        element={<ProtectedRoute path="/empresas" page={PayrollCompanies} isLogged={isLogged} />}
      />
      <Route
        path="/empresas/detalle/:cId"
        element={
          <ProtectedRoute path="/empresas" page={PayrollCompaniesDetail} isLogged={isLogged} />
        }
      />
      <Route
        path="/empresas/editar/:cId"
        element={
          <ProtectedRoute path="/empresas/editar" page={PayrollUpdate} isLogged={isLogged} />
        }
      />
      <Route
        path="/empresas/nomina/registro"
        element={
          <ProtectedRoute
            path="/empresas/nomina/registro"
            page={PayrollRegister}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/empresas/nomina"
        element={
          <ProtectedRoute path="/empresas/nomina" page={EmployeeRequestList} isLogged={isLogged} />
        }
      />
      <Route
        path="/empresas/nomina/detalles/:pcId"
        element={
          <ProtectedRoute
            path="/empresas/nomina/"
            page={EmployeeRequestDetail}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/empresas/nomina/editar/:pcId"
        element={
          <ProtectedRoute
            path="/empresas/nomina/registro"
            isLogged={isLogged}
            page={EmployeeRegister}
          />
        }
      />
      <Route
        path="/inversionistas/solicitudes"
        element={
          <ProtectedRoute
            path="/inversionistas/solicitudes"
            page={InvestorRequestList}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/inversionistas/detalle/:iId"
        element={
          <ProtectedRoute path="/inversionistas" page={InvestorDetail} isLogged={isLogged} />
        }
      />
      <Route
        path="/inversionistas/"
        element={<ProtectedRoute path="/inversionistas/" page={InvestorList} isLogged={isLogged} />}
      />
      <Route
        path="/utilities"
        element={<ProtectedRoute path="/utilities" page={Utilities} isLogged={isLogged} />}
      />
      <Route
        path="/characteristics"
        element={
          <ProtectedRoute path="/characteristics" page={Characteristics} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos"
        element={<ProtectedRoute path="/creditos" page={CreditsList} isLogged={isLogged} />}
      />
      <Route
        path="/creditos360"
        element={<ProtectedRoute path="/creditos360" page={CreditsList360} isLogged={isLogged} />}
      />
      <Route
        path="/creditos/MigracionDeCreditos"
        element={
          <ProtectedRoute
            path="/creditos/MigracionDeCreditos"
            page={CreditMigration}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/atributos/"
        element={
          <ProtectedRoute path="/creditos/atributos" page={AttributesList} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/rechazado"
        element={
          <ProtectedRoute
            path="/creditos/rechazado"
            page={RejectedCreditsList}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/egresos"
        element={<ProtectedRoute path="/tesoreria/egresos" page={Expense} isLogged={isLogged} />}
      />
      <Route
        path="/tesoreria/expenses"
        element={
          <ProtectedRoute path="/tesoreria/expenses" page={ExpensesListP} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/expenses/see/:ecId"
        element={
          <ProtectedRoute path="/tesoreria/expenses" page={EgressListDetail} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/newIncome"
        element={
          <ProtectedRoute path="/tesoreria/newIncome" page={NewIncome} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/incomes"
        element={<ProtectedRoute path="/tesoreria/incomes" page={EntryListP} isLogged={isLogged} />}
      />
      <Route
        path="/tesoreria/incomes/see/:icId"
        element={
          <ProtectedRoute path="/tesoreria/incomes" page={EntryDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/creditos"
        element={
          <ProtectedRoute
            path="/tesoreria/creditos"
            page={TreasuryListCredits}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/annulment/register"
        element={
          <ProtectedRoute
            path="/tesoreria/annulment/register"
            page={AnnulmentsRegister}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/annulment"
        element={
          <ProtectedRoute path="/tesoreria/annulment" page={CanceledListP} isLogged={isLogged} />
        }
      />

      <Route
        path="/tesoreria/accountingReports"
        element={
          <ProtectedRoute
            path="/tesoreria/accountingReports"
            page={AccountingReports}
            isLogged={isLogged}
          />
        }
      />

      <Route
        path="/tesoreria/annulment/see/:aId"
        element={
          <ProtectedRoute path="/tesoreria/annulment" page={CanceledListPage} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/informe/pago/pagaduria"
        element={
          <ProtectedRoute
            path="/tesoreria/informe/pago/pagaduria"
            page={PaymentReports}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/egreso/list"
        element={
          <ProtectedRoute
            path="/tesoreria/egreso/list"
            page={MoneyInFavorList}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/egreso/detalle/:epmId"
        element={
          <ProtectedRoute path="/tesoreria/egreso" page={MoneyInFavorDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/moneyInFavor"
        element={
          <ProtectedRoute path="/tesoreria/moneyInFavor" page={MoneyInFavor} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/ingresos"
        element={
          <ProtectedRoute path="/tesoreria/ingresos" page={IncomeCreated} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/credit-reports"
        element={
          <ProtectedRoute
            path="/tesoreria/credit-reports"
            page={CreditReports}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/quota-reports"
        element={
          <ProtectedRoute path="/tesoreria/quota-reports" page={QuotaReports} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/moconta"
        element={
          <ProtectedRoute
            path="/tesoreria/reportes"
            page={AccountingMovement}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/tesoreria/reportes"
        element={
          <ProtectedRoute path="/tesoreria/reportes" page={thirdsReport} isLogged={isLogged} />
        }
      />
      <Route
        path="/tesoreria/reportes-seguros"
        element={
          <ProtectedRoute
            path="/tesoreria/reportes-seguros"
            page={insuranceReports}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/grupo/atributos/crear"
        element={
          <ProtectedRoute
            path="/creditos/grupo/atributos"
            page={CreateAttributeGroupP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/simulator"
        element={
          <ProtectedRoute path="/creditos/simulator" page={SimulatorP} isLogged={isLogged} />
        }
      />
      <Route
        path="/cartera/cierre-mensual"
        element={
          <ProtectedRoute
            path="/cartera/cierre-mensual"
            page={ClosingMonthlyP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/portfolio-age/:filterBy"
        element={
          <ProtectedRoute
            path="/creditos/portfolio-age"
            page={PortfolioByAge}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/grupo/atributos/crear/:gaId"
        element={
          <ProtectedRoute
            path="/creditos/grupo/atributos"
            page={CreateAttributeGroupP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/grupo/atributos"
        element={
          <ProtectedRoute
            path="/creditos/grupo/atributos"
            page={GroupAttributeP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/grupo/atributos/detalle/:gaId"
        element={
          <ProtectedRoute
            path="/creditos/grupo/atributos"
            page={GroupAttributeDetail}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/group"
        element={
          <ProtectedRoute path="/creditos/group" page={CreditGroupList} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/detalle/:cId"
        element={<ProtectedRoute path="/creditos" page={Request} isLogged={isLogged} />}
      />
      {/* INICIO FASES */}
      <Route
        path="/phases"
        element={<ProtectedRoute path="/phases" page={PhaseCards} isLogged={isLogged} />}
      />
      <Route
        path="/phases/:pId"
        element={<ProtectedRoute path="/phases" page={PhaseListCredits} isLogged={isLogged} />}
      />
      <Route
        path="/credits/request/:cId"
        element={<ProtectedRoute path="/phases" page={Request} isLogged={isLogged} />}
      />
      {/* FIN FASES */}
      <Route
        path="/creditos/type/"
        element={
          <ProtectedRoute path="/creditos/type" page={CreditTypeListP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/detalle/:ctId"
        element={
          <ProtectedRoute path="/creditos/detalle" page={CreditTypeDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/Reliquidacion"
        element={
          <ProtectedRoute
            path="/creditos/Reliquidacion"
            page={ReliquidacionP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/atributo/detalle/:taId"
        element={
          <ProtectedRoute path="/creditos/atributo" page={AttributesDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/beneficiarios/detalle/:bId"
        element={
          <ProtectedRoute path="/beneficiarios" page={BeneficiariesDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/tipos/detalle/:ctId"
        element={
          <ProtectedRoute path="/creditos/type" page={CreditTypeDetailP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/tipo/crear"
        element={
          <ProtectedRoute path="/creditos/type" page={CreditTypeCreateP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/tipo/actualizar/:ctId"
        element={
          <ProtectedRoute path="/creditos/type" page={CreditTypeCreateP} isLogged={isLogged} />
        }
      />
      {/* <Route
        path="/phases/list"
        element={<ProtectedRoute data={{...decoded, loading}} page={CreditRequest} isLogged={isLogged} />}
      />
         */}
      <Route
        path="/inversionistas/registro"
        element={
          <ProtectedRoute
            path="/inversionistas/registro"
            page={InvestorRegisterPage}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/inversionistas/editar/:iId"
        element={
          <ProtectedRoute path="/inversionistas" page={InvestorRegisterPage} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/atributos/registro"
        element={
          <ProtectedRoute
            path="/creditos/atributos"
            page={AttributeTypeRegisterPage}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditos/atributos/editar/:taId"
        element={
          <ProtectedRoute
            path="/creditos/atributos"
            page={AttributeTypeRegisterPage}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/vendedores/registro"
        element={
          <ProtectedRoute
            path="/vendedores/registro"
            page={SellersRegisterPage}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/vendedores/editar/:sId"
        element={
          <ProtectedRoute
            path="/vendedores/registro"
            page={SellersRegisterPage}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/pagosElectronicos"
        element={
          <ProtectedRoute
            path="/pagosElectronicos"
            page={ElectronicPaymentsP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/pagosElectronicos/detalle/:id"
        element={
          <ProtectedRoute
            path="/pagosElectronicos"
            page={ElectronicPaymentDetails}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/parameters/account"
        element={
          <ProtectedRoute
            path="/parameters/account"
            page={ParametersAccountP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/parameters/general"
        element={
          <ProtectedRoute
            path="/parameters/general"
            page={ParametersGeneralP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/parameters/emailconfiguration"
        element={
          <ProtectedRoute
            path="/parameters/emailconfiguration"
            page={ParametersEmailP}
            isLogged={isLogged}
          />
        }
      />
      <Route
        path="/creditBatches"
        element={
          <ProtectedRoute path="/creditBatches" page={CreatedGroupLostP} isLogged={isLogged} />
        }
      />
      <Route
        path="/listas/list/:id"
        element={<ProtectedRoute path="/listas/list" page={LostListP} isLogged={isLogged} />}
      />
      <Route
        path="/registro/listaDeLotes/:id"
        element={
          <ProtectedRoute path="/registro/listaDeLotes" page={CreateBatchP} isLogged={isLogged} />
        }
      />
      <Route
        path="/administration/"
        element={
          <ProtectedRoute path="/administration" page={AdministrationP} isLogged={isLogged} />
        }
      />
      <Route
        path="/credits/update/:cId"
        element={<ProtectedRoute path="/phases" page={EditCreditP} isLogged={isLogged} />}
      />
      <Route
        path="/creditos/groupLine"
        element={<ProtectedRoute path="/creditos" page={GroupCreditLinePage} isLogged={isLogged} />}
      />
      <Route
        path="/creditos/groupLine/crear"
        element={
          <ProtectedRoute path="/creditos/groupLine" page={CreateGroupLineP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/groupLine/crear/:gclId"
        element={
          <ProtectedRoute path="/creditos/groupLine" page={CreateGroupLineP} isLogged={isLogged} />
        }
      />
      <Route
        path="/creditos/groupLine/detail/:gclId"
        element={
          <ProtectedRoute
            path="/creditos/groupLine"
            page={GroupCreditLineDetailP}
            isLogged={isLogged}
          />
        }
      />
      <Route path="/pdfCriers" element={<Test2 />} />
      <Route path="/cirenio" element={<Cirenio />} />
      {/* <Route path="/jspdf" element={<Jspdf />} /> */}
      {/* Rutas Sin Protección */}
      <Route path="/payment/response/:provider/" element={<RetrievePaymentResponse />} />
      <Route path="/retrievePayment/:provider/:transaction" element={<TransactionDetailP />} />
      <Route path="/test" element={<Test />} />
      <Route path="/credits" element={<CreditFormOpen />} />
      <Route path="/finish/openCredit" element={<FinishCreditFormOpen />} />
      <Route path="/openForm" element={<OpenForm />} />
      <Route path="/nogue" element={<HandleDocsNogue />} />
      <Route path="/integrationAdo" element={<IntegrationResponseAdo />} />
      <Route path="/welcome/user/active/:uId/:uToken" element={<ActiveAccountPayroll />} />
      <Route path="/welcome/user/pass/:uId/:uToken" element={<SetPasswordP />} />
      <Route path="/recoverPassword" element={<RecoverPasswordP />} />
      <Route path="/welcome/user/active/:uId/:code" element={<UserVerification />} />
    </Routes>
  );
};

export default AllRoutes;
